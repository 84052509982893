import request from '@/utils/request'

export function listPage(params) {
    return request({
        method:'get',
        url:'/api/amazon/asin/productCostListPage',
        params
    })
}

// 查询初始化
export function pageInit() {
    return request({
        method:'get',
        url:'/api/amazon/asin/pageInit',
    });
}

//设置成本初始化
export function setCostInit(id) {
    return request({
        url:`/api/amazon/asin/updateCostInit/${id}`,
        method:'get'
    });
}

//提交更改
export function set(data) {
    return request({
        method:'put',
        url:'/api/amazon/asin/updateProductCost',
        data
    });
}
export function setBatch(marketPlaceId) {
    return request({
        method:'get',
        url:`/api/amazon/asin/batchUpdateCostInit/${marketPlaceId}`,
    });
}